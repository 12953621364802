import React from 'react';

import {
  HTMLCustomVariantsAwareProps,
  createComponent,
  useTailwindVariants,
} from '@/ui/system';

import { cn } from '@/lib/utils';

import { CardVariants, cardVariants } from './card.cx';

type BaseProps = HTMLCustomVariantsAwareProps<'button', CardVariants>;

export interface CardProps extends BaseProps {}

const Card = createComponent<CardProps>(
  (
    {
      as,
      children,
      mode = 'default',
      loading = false,
      selected = false,
      disabled = false,
      type = 'button',
      ...props
    },
    ref,
  ) => {
    const blueprint = useTailwindVariants(
      { ...props, mode, loading, selected, disabled },
      cardVariants,
    );

    const isAction = mode === 'action';
    const role = isAction ? 'button' : 'article';

    const Component = as || role;
    const componentProps = isAction ? { type } : {};

    return (
      <Component
        ref={ref}
        aria-checked={selected}
        aria-disabled={disabled}
        disabled={disabled}
        role={props.role || role}
        {...blueprint.ownProps}
        {...componentProps}
      >
        {children}
      </Component>
    );
  },
);

Card.displayName = 'Card';

const CardHeader = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      'flex flex-col bg-inset-default leading-1 font-inter text-body-default font-medium py-units-unit20 px-units-unit16 w-full',
      className,
    )}
    {...props}
  />
));
CardHeader.displayName = 'CardHeader';

const CardContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('py-units-unit20 px-units-unit16', className)}
    {...props}
  />
));
CardContent.displayName = 'CardContent';

export { Card, CardHeader, CardContent };
