import { defineVariants } from '@/ui/system';

export const chipVariants = defineVariants({
  slots: {
    root: 'relative isolate inline-flex gap-1 select-none appearance-none items-center whitespace-nowrap align-middle font-inter font-medium outline-none justify-center border border-transparent',
    icon: '',
  },
  variants: {
    mode: {
      action: '',
      status: '',
    },
    appearance: {
      primary: '',
      success: '',
      danger: '',
      warning: '',
      neutral: '',
    },
    variant: {
      faded: '',
      filled: '',
      outline: '',
      plain: '',
    },
    selected: {
      true: '',
      false: '',
    },
    size: {
      small: {
        icon: 'h-units-unit12 w-units-unit12',
        root: 'p-1 !text-body-x-small tracking-body-x-small leading-body-x-small',
      },
      medium: {
        icon: 'h-units-unit14 w-units-unit14',
        root: 'py-[5px] px-1 !text-body-small tracking-body-small leading-body-small',
      },
      large: {
        icon: 'h-units-unit16 w-units-unit16',
        root: 'py-1.5 px-1 !text-body-default tracking-body-default leading-body-default',
      },
      extraLarge: {
        icon: 'h-units-unit20 w-units-unit20',
        root: 'py-2 px-3 !text-content-heading-alternative typography-body-default font-medium _gap-x-units-unit10',
      },
    },
    radius: {
      default: '',
      full: {
        root: 'rounded-full',
      },
    },
    disabled: {
      true: {
        root: 'pointer-events-none cursor-not-allowed opacity-30',
      },
    },
  },
  compoundVariants: [
    // -------------------------------------------
    // appearance = primary
    // -------------------------------------------
    {
      mode: 'action',
      variant: 'faded',
      appearance: 'primary',
      class: {
        root: [
          'text-feedback-information-ghost-text',
          'bg-feedback-information-ghost-default',
          'hover:bg-feedback-information-ghost-hovered',
        ],
      },
    },
    {
      mode: 'status',
      variant: 'faded',
      appearance: 'primary',
      class: {
        root: [
          'text-feedback-information-ghost-text',
          'bg-feedback-information-ghost-default',
        ],
      },
    },
    {
      mode: 'action',
      variant: 'filled',
      appearance: 'primary',
      class: {
        root: [
          'text-feedback-information-filled-text',
          'bg-feedback-information-filled-default',
          'hover:bg-feedback-information-filled-hovered',
        ],
      },
    },
    {
      mode: 'status',
      variant: 'filled',
      appearance: 'primary',
      class: {
        root: [
          'text-feedback-information-filled-text',
          'bg-feedback-information-filled-default',
        ],
      },
    },
    {
      mode: 'action',
      selected: false,
      variant: 'outline',
      appearance: 'primary',
      class: {
        root: [
          'bg-white',
          'text-feedback-information-outline-text',
          'border border-feedback-information-outline-default',
          'hover:bg-feedback-information-outline-hovered',
          'hover:border-feedback-information-outline-hovered',
        ],
      },
    },
    {
      mode: 'action',
      selected: true,
      variant: 'outline',
      appearance: 'primary',
      class: {
        root: [
          'bg-white',
          'text-feedback-information-outline-text',
          'border border-feedback-information-filled-default',
        ],
      },
    },
    {
      mode: 'status',
      variant: 'outline',
      appearance: 'primary',
      class: {
        root: [
          'bg-white',
          'text-feedback-information-outline-text',
          'border border-feedback-information-outline-default',
        ],
      },
    },
    {
      mode: 'action',
      variant: 'plain',
      appearance: 'primary',
      class: {
        root: [
          'bg-white',
          'text-feedback-information-filled-default',
          'hover:text-feedback-information-filled-hovered',
        ],
      },
    },
    {
      mode: 'status',
      variant: 'plain',
      appearance: 'primary',
      class: {
        root: ['bg-white', 'text-feedback-information-filled-default'],
      },
    },
    // --------------------------------
    // appearance = success
    // --------------------------------
    {
      mode: 'action',
      variant: 'faded',
      appearance: 'success',
      class: {
        root: [
          'text-feedback-success-ghost-text',
          'bg-feedback-success-ghost-default',
          'hover:bg-feedback-success-ghost-hovered',
        ],
      },
    },
    {
      mode: 'status',
      variant: 'faded',
      appearance: 'success',
      class: {
        root: [
          'text-feedback-success-ghost-text',
          'bg-feedback-success-ghost-default',
        ],
      },
    },
    {
      mode: 'action',
      variant: 'filled',
      appearance: 'success',
      class: {
        root: [
          'text-feedback-success-filled-text',
          'bg-feedback-success-filled-default',
          'hover:bg-feedback-success-filled-hovered',
        ],
      },
    },
    {
      mode: 'status',
      variant: 'filled',
      appearance: 'success',
      class: {
        root: [
          'text-feedback-success-filled-text',
          'bg-feedback-success-filled-default',
        ],
      },
    },
    {
      mode: 'action',
      selected: false,
      variant: 'outline',
      appearance: 'success',
      class: {
        root: [
          'bg-white',
          'text-feedback-success-outline-text',
          'border border-feedback-success-outline-default',
          'hover:bg-feedback-success-outline-hovered',
          'hover:border-feedback-success-outline-hovered',
        ],
      },
    },
    {
      mode: 'action',
      selected: true,
      variant: 'outline',
      appearance: 'success',
      class: {
        root: [
          'bg-white',
          'text-feedback-success-outline-text',
          'border border-feedback-success-filled-default',
        ],
      },
    },
    {
      mode: 'status',
      variant: 'outline',
      appearance: 'success',
      class: {
        root: [
          'bg-white',
          'text-feedback-success-outline-text',
          'border border-feedback-success-outline-default',
        ],
      },
    },
    {
      mode: 'action',
      variant: 'plain',
      appearance: 'success',
      class: {
        root: [
          'bg-white',
          'text-feedback-success-filled-default',
          'hover:text-feedback-success-filled-hovered',
        ],
      },
    },
    {
      mode: 'status',
      variant: 'plain',
      appearance: 'success',
      class: {
        root: ['bg-white', 'text-feedback-success-filled-default'],
      },
    },
    // --------------------------------
    // appearance = danger
    // --------------------------------
    {
      mode: 'action',
      variant: 'faded',
      appearance: 'danger',
      class: {
        root: [
          'text-feedback-danger-ghost-text',
          'bg-feedback-danger-ghost-default',
          'hover:bg-feedback-danger-ghost-hovered',
        ],
      },
    },
    {
      mode: 'status',
      variant: 'faded',
      appearance: 'danger',
      class: {
        root: [
          'text-feedback-danger-ghost-text',
          'bg-feedback-danger-ghost-default',
        ],
      },
    },
    {
      mode: 'action',
      variant: 'filled',
      appearance: 'danger',
      class: {
        root: [
          'text-feedback-danger-filled-text',
          'bg-feedback-danger-filled-default',
          'hover:bg-feedback-danger-filled-hovered',
        ],
      },
    },
    {
      mode: 'status',
      variant: 'filled',
      appearance: 'danger',
      class: {
        root: [
          'text-feedback-danger-filled-text',
          'bg-feedback-danger-filled-default',
        ],
      },
    },
    {
      mode: 'action',
      selected: false,
      variant: 'outline',
      appearance: 'danger',
      class: {
        root: [
          'bg-white',
          'text-feedback-danger-outline-text',
          'border border-feedback-danger-outline-default',
          'hover:bg-feedback-danger-outline-hovered',
          'hover:border-feedback-danger-outline-hovered',
        ],
      },
    },
    {
      mode: 'action',
      selected: true,
      variant: 'outline',
      appearance: 'danger',
      class: {
        root: [
          'bg-white',
          'text-feedback-danger-outline-text',
          'border border-feedback-danger-filled-default',
        ],
      },
    },
    {
      mode: 'status',
      variant: 'outline',
      appearance: 'danger',
      class: {
        root: [
          'bg-white',
          'text-feedback-danger-outline-text',
          'border border-feedback-danger-outline-default',
        ],
      },
    },
    {
      mode: 'action',
      variant: 'plain',
      appearance: 'danger',
      class: {
        root: [
          'bg-white',
          'text-feedback-danger-filled-default',
          'hover:text-feedback-danger-filled-hovered',
        ],
      },
    },
    {
      mode: 'status',
      variant: 'plain',
      appearance: 'danger',
      class: {
        root: ['bg-white', 'text-feedback-danger-filled-default'],
      },
    },
    // --------------------------------
    // appearance = warning
    // --------------------------------
    {
      mode: 'action',
      variant: 'faded',
      appearance: 'warning',
      class: {
        root: [
          'text-feedback-warning-ghost-text',
          'bg-feedback-warning-ghost-default',
          'hover:bg-feedback-warning-ghost-hovered',
        ],
      },
    },
    {
      mode: 'status',
      variant: 'faded',
      appearance: 'warning',
      class: {
        root: [
          'text-feedback-warning-ghost-text',
          'bg-feedback-warning-ghost-default',
        ],
      },
    },
    {
      mode: 'action',
      variant: 'filled',
      appearance: 'warning',
      class: {
        root: [
          'text-feedback-warning-filled-text',
          'bg-feedback-warning-filled-default',
          'hover:bg-feedback-warning-filled-hovered',
        ],
      },
    },
    {
      mode: 'status',
      variant: 'filled',
      appearance: 'warning',
      class: {
        root: [
          'text-feedback-warning-filled-text',
          'bg-feedback-warning-filled-default',
        ],
      },
    },
    {
      mode: 'action',
      selected: false,
      variant: 'outline',
      appearance: 'warning',
      class: {
        root: [
          'bg-white',
          'text-feedback-warning-outline-text',
          'border border-feedback-warning-outline-default',
          'hover:bg-feedback-warning-outline-hovered',
          'hover:border-feedback-warning-outline-hovered',
        ],
      },
    },
    {
      mode: 'action',
      selected: true,
      variant: 'outline',
      appearance: 'warning',
      class: {
        root: [
          'bg-white',
          'text-feedback-warning-outline-text',
          'border border-feedback-warning-filled-default',
        ],
      },
    },
    {
      mode: 'status',
      variant: 'outline',
      appearance: 'warning',
      class: {
        root: [
          'bg-white',
          'text-feedback-warning-outline-text',
          'border border-feedback-warning-outline-default',
        ],
      },
    },
    {
      mode: 'action',
      variant: 'plain',
      appearance: 'warning',
      class: {
        root: [
          'bg-white',
          'text-feedback-warning-filled-default',
          'hover:text-feedback-warning-filled-hovered',
        ],
      },
    },
    {
      mode: 'status',
      variant: 'plain',
      appearance: 'warning',
      class: {
        root: ['bg-white', 'text-feedback-warning-filled-default'],
      },
    },
    // --------------------------------
    // appearance = neutral
    // --------------------------------
    {
      mode: 'action',
      variant: 'faded',
      appearance: 'neutral',
      class: {
        root: [
          'text-feedback-neutral-ghost-text',
          'bg-feedback-neutral-ghost-default',
          'hover:bg-feedback-neutral-ghost-hovered',
        ],
      },
    },
    {
      mode: 'status',
      variant: 'faded',
      appearance: 'neutral',
      class: {
        root: [
          'text-feedback-neutral-ghost-text',
          'bg-feedback-neutral-ghost-default',
        ],
      },
    },
    {
      mode: 'action',
      variant: 'filled',
      appearance: 'neutral',
      class: {
        root: [
          'text-feedback-neutral-filled-text',
          'bg-feedback-neutral-filled-default',
          'hover:bg-feedback-neutral-filled-hovered',
        ],
      },
    },
    {
      mode: 'status',
      variant: 'filled',
      appearance: 'neutral',
      class: {
        root: [
          'text-feedback-neutral-filled-text',
          'bg-feedback-neutral-filled-default',
        ],
      },
    },
    {
      mode: 'action',
      selected: false,
      variant: 'outline',
      appearance: 'neutral',
      class: {
        root: [
          'bg-white',
          'text-feedback-neutral-outline-text',
          'border border-feedback-neutral-outline-default',
          'hover:bg-feedback-neutral-outline-hovered',
          'hover:border-feedback-neutral-outline-hovered',
        ],
      },
    },
    {
      mode: 'action',
      selected: true,
      variant: 'outline',
      appearance: 'neutral',
      class: {
        root: [
          'bg-white',
          'text-feedback-neutral-outline-text',
          'border border-feedback-neutral-filled-default',
        ],
      },
    },
    {
      mode: 'status',
      variant: 'outline',
      appearance: 'neutral',
      class: {
        root: [
          'bg-white',
          'text-feedback-neutral-outline-text',
          'border border-feedback-neutral-outline-default',
        ],
      },
    },
    {
      mode: 'action',
      variant: 'plain',
      appearance: 'neutral',
      class: {
        root: [
          'bg-white',
          'text-feedback-neutral-filled-default',
          'hover:text-feedback-neutral-filled-hovered',
        ],
      },
    },
    {
      mode: 'status',
      variant: 'plain',
      appearance: 'neutral',
      class: {
        root: ['bg-white', 'text-feedback-neutral-filled-default'],
      },
    },
    // --------------------------------
    // radius = default
    // --------------------------------
    {
      size: 'small',
      radius: 'default',
      class: {
        root: 'rounded-radius2',
      },
    },
    {
      size: 'medium',
      radius: 'default',
      class: {
        root: 'rounded-radius3',
      },
    },
    {
      size: 'large',
      radius: 'default',
      class: {
        root: 'rounded-radius4',
      },
    },
  ],
  defaultVariants: {
    size: 'medium',
    mode: 'status',
    variant: 'faded',
    radius: 'default',
    appearance: 'primary',
  },
});

export type ChipVariants = typeof chipVariants;
