import {
  HTMLCustomVariantsAwareProps,
  createComponent,
  useTailwindVariants,
} from '@/ui/system';

import { CardIconVariants, cardIconVariants } from './card-icon.cx';

type BaseProps = HTMLCustomVariantsAwareProps<'span', CardIconVariants>;

export type CardIconProps = BaseProps;

export const CardIcon = createComponent<CardIconProps>(
  ({ children, as: Component = 'span', ...props }, ref) => {
    const blueprint = useTailwindVariants(props, cardIconVariants);

    return (
      <Component
        ref={ref}
        aria-hidden="true"
        {...blueprint.ownProps}
        role={props.role || 'figure'}
      >
        {children}
      </Component>
    );
  },
);

CardIcon.displayName = 'CardIcon';
