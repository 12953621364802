import { useId } from 'react';

import { cn } from '@/lib/utils';

import { Flex } from '../flex';
import { Text } from '../text/text';
import {
  TextFieldHelperText,
  TextFieldHelperTextProps,
} from './text-field-helper-text';

export type TextFieldProps = React.ComponentPropsWithoutRef<'div'> & {
  label?: string;
  error?: string;
  disabled?: boolean;
  placeholder?: string;
  direction?: 'column' | 'row';
  classNames?: {
    root?: string;
    label?: string;
    inputWrapper?: string;
    errorText?: string;
  };
  renderInput: ({
    id,
    error,
    disabled,
  }: {
    id?: string;
    error?: boolean;
    disabled?: boolean;
  }) => React.ReactNode;
};

/**
 * A generic text field component that handles labels and errors for form fields.
 */
export function TextField({
  id: parentId,
  label,
  error,
  disabled = false,
  className,
  classNames,
  renderInput,
  direction = 'column',
  ...props
}: TextFieldProps) {
  const id = useId();

  const idToUse = parentId || id;

  return (
    <Flex
      {...props}
      direction={direction}
      className={cn('space-y-2', className, classNames?.root)}
    >
      {label && (
        <Text
          as="label"
          weight="semibold"
          htmlFor={idToUse}
          variant="body-small"
          color="content-body-default"
          className={cn(
            {
              'text-content-label-default': disabled,
            },
            classNames?.label,
          )}
        >
          {label}
        </Text>
      )}
      <div className={cn('space-y-1', classNames?.inputWrapper)}>
        {renderInput({
          disabled,
          id: idToUse,
          error: Boolean(error),
        })}
        {error && (
          <TextFieldError
            className={classNames?.errorText}
            aria-label={`${idToUse}-input-alert`}
          >
            {error}
          </TextFieldError>
        )}
      </div>
    </Flex>
  );
}

export function TextFieldError(props: TextFieldHelperTextProps) {
  return (
    <TextFieldHelperText role="alert" color="semantic-red-d7" {...props} />
  );
}
