'use client';

import NextLink from 'next/link';
import {
  useRouter as useNextRouter,
  useSearchParams as useNextSearchParams,
  usePathname,
} from 'next/navigation';
import { useCallback } from 'react';

import { useSearchParams } from '@/shared/hooks';

/** Wrapper around useRouter that preserves the search params. */
export function useRouter() {
  const router = useNextRouter();
  const { searchParams } = useSearchParams();

  const preserveSearchParams = (href: string) => {
    const [path, searchParamsInHref] = href.split('?');

    const newSearchParams = new URLSearchParams(searchParams);
    if (newSearchParams.toString()) {
      new URLSearchParams(searchParamsInHref).forEach((value, key) => {
        newSearchParams.set(key, value);
      });

      const stringifiedSearchParams = newSearchParams.toString();
      return `${path}?${stringifiedSearchParams}`;
    }

    return href;
  };

  const push = useCallback(
    (href: string, options?: { scroll?: boolean }) => {
      router.push(preserveSearchParams(href), options);
    },
    [router, searchParams],
  );

  const replace = useCallback(
    (href: string, options?: { scroll?: boolean }) => {
      router.replace(preserveSearchParams(href), options);
    },
    [router, searchParams],
  );

  return {
    ...router,
    push,
    replace,
  };
}

/** Wrapper around Link that preserves the search params. */
export function Link({ href, ...props }: Parameters<typeof NextLink>[0]) {
  const { searchParams } = useSearchParams();
  const stringifiedSearchParams = searchParams.toString();

  if (stringifiedSearchParams) {
    href += `?${stringifiedSearchParams}`;
  }

  return <NextLink role="link" href={href} {...props} />;
}
