/* eslint-disable jsx-a11y/alt-text */
import { DialogProps } from '@radix-ui/react-dialog';
import { StaticImport } from 'next/dist/shared/lib/get-img-props';
import Image, { ImageProps } from 'next/image';
import React, { SVGProps } from 'react';
import ReactDOM from 'react-dom/client';

import { InformationSquare } from '@/ui/svgs/information-square';
import { WarningTriangle } from '@/ui/svgs/warning-triangle';
import { createComponent } from '@/ui/system';

import { breakpoints, useMediaQuery } from '@/shared/hooks';

import { cn, logError } from '@/lib/utils';

import {
  BottomSheet,
  BottomSheetContent,
  BottomSheetCover,
  BottomSheetDescription,
  BottomSheetFooter,
  BottomSheetHeader,
  BottomSheetTitle,
} from '../bottom-sheet';
import {
  Dialog,
  DialogAction,
  DialogBody,
  DialogBodyProps,
  DialogClose,
  DialogContent,
  DialogContentProps,
  DialogCover,
  DialogCoverProps,
  DialogDescription,
  DialogFooter,
  DialogFooterProps,
  DialogHeader,
  DialogHeaderProps,
  DialogIcon,
  DialogSize,
  DialogTitle,
} from '../dialog';
import { PageLoader } from '../page';

type DialogEvent = { confirm: boolean };

interface AlertDialogThumbnailProps extends Omit<ImageProps, 'src'> {
  illustration?: React.FC<SVGProps<SVGSVGElement>>;
  src?: string | StaticImport;
}

export interface AlertDialogProps extends DialogProps {
  id?: string;
  title?: string;
  size?: DialogSize;
  loading?: boolean;
  description?: string;
  icon?: React.ReactNode;
  cover?: React.ReactNode;
  content?: React.ReactNode;
  bodyProps?: DialogBodyProps;
  disableClickOutside?: boolean;
  footerProps?: DialogFooterProps;
  headerProps?: DialogHeaderProps;
  contentProps?: Omit<DialogContentProps, 'onAnimationEnd'>;
  renderButtons?: (props: {
    close: () => void;
    confirm: () => void;
  }) => React.ReactNode;
}

interface DialogPartEntry {
  props: Partial<AlertDialogProps>;
  reject: (reason: string) => void;
  resolve: (event: DialogEvent) => any;
}

type DialogPartFactory = (entry: DialogPartEntry) => DialogPartEntry;

export enum AlertDialogClassName {
  Content = 'alert-dialog-content',
  Overlay = 'alert-dialog-overlay',
  Container = 'alert-dialog-container',
}

function remove(): Promise<void> {
  return new Promise((resolve) => {
    const contents = Array.from(
      document.getElementsByClassName(AlertDialogClassName.Content),
    );
    const overlays = Array.from(
      document.getElementsByClassName(AlertDialogClassName.Overlay),
    );
    const containers = Array.from(
      document.getElementsByClassName(AlertDialogClassName.Container),
    );

    try {
      contents.forEach((content) => content?.remove());
      overlays.forEach((overlay) => overlay?.remove());
      containers.forEach((container) => container?.remove());
    } catch (error) {
      logError('Error removing dialog', error);
    } finally {
      resolve();
    }
  });
}

const createDialogPart = (factory: DialogPartFactory) => {
  return (props: DialogPartEntry['props'] = {}) => {
    return new Promise<DialogEvent>(async (resolve, reject) => {
      const resolved = factory({ props, resolve, reject });

      // Remove any existing dialogs
      await remove();

      // Create a new dialog
      const div = document.createElement('div');
      div.classList.add(AlertDialogClassName.Container);

      // Append the dialog to the body
      const container = document.body.appendChild(div);
      const root = ReactDOM.createRoot(container!);

      const resolvedProps = resolved.props;

      async function onOpenChange({
        open = false,
        confirm = false,
      }: { open?: boolean; confirm?: boolean } = {}) {
        resolved.resolve({ confirm });
        resolvedProps.onOpenChange?.(open);

        if (!open) {
          root.unmount();
          await remove();
        }
      }

      const shouldRenderDialog = window.matchMedia(
        `(min-width: ${breakpoints['sm']}px)`,
      ).matches;

      const dialogProps = {
        open: true,
        onOpenChange: (open: boolean) => onOpenChange({ open }),
        ...resolvedProps,
      };

      const onInteractOutside = (event: Event) => {
        if (resolvedProps.disableClickOutside) {
          event.preventDefault();
        }
      };

      if (resolvedProps.loading) {
        root.render(<PageLoader />);
        return;
      }

      if (shouldRenderDialog) {
        root.render(
          <Dialog {...dialogProps}>
            <DialogContent
              id={resolvedProps.id}
              size={resolvedProps.size || 'auto'}
              onInteractOutside={onInteractOutside}
              className={AlertDialogClassName.Content}
              overlayProps={{ className: AlertDialogClassName.Overlay }}
              {...resolvedProps.contentProps}
            >
              {resolvedProps.cover}
              <DialogBody {...resolvedProps.bodyProps}>
                <DialogHeader {...resolvedProps.headerProps}>
                  {resolvedProps.icon}
                  <DialogTitle>{resolvedProps.title}</DialogTitle>
                  {resolvedProps.description && (
                    <DialogDescription>
                      {resolvedProps.description}
                    </DialogDescription>
                  )}
                </DialogHeader>
                {resolvedProps.content}
                {resolvedProps.renderButtons && (
                  <DialogFooter {...resolvedProps.footerProps}>
                    {resolvedProps.renderButtons({
                      close: () => onOpenChange(),
                      confirm: () => onOpenChange({ confirm: true }),
                    })}
                  </DialogFooter>
                )}
              </DialogBody>
            </DialogContent>
          </Dialog>,
        );
        return;
      }

      root.render(
        <BottomSheet {...dialogProps}>
          <BottomSheetContent
            id={resolvedProps.id}
            onInteractOutside={onInteractOutside}
            {...resolvedProps.contentProps}
          >
            {resolvedProps.cover}
            <BottomSheetHeader {...resolvedProps.headerProps}>
              {resolvedProps.icon}
              <BottomSheetTitle>{resolvedProps.title}</BottomSheetTitle>
              {resolvedProps.description && (
                <BottomSheetDescription>
                  {resolvedProps.description}
                </BottomSheetDescription>
              )}
            </BottomSheetHeader>
            {resolvedProps.content}
            {resolvedProps.renderButtons && (
              <BottomSheetFooter {...resolvedProps.footerProps}>
                {resolvedProps.renderButtons({
                  close: () => onOpenChange(),
                  confirm: () => onOpenChange({ confirm: true }),
                })}
              </BottomSheetFooter>
            )}
          </BottomSheetContent>
        </BottomSheet>,
      );
    });
  };
};

export type AlertDialogVariant =
  | 'danger'
  | 'warning'
  | 'confirmation'
  | 'success'
  | 'loading';

export const alertDialog = {
  remove,
  loading: createDialogPart((entry) => ({
    ...entry,
    props: {
      loading: true,
      ...entry.props,
    },
  })),
  danger: createDialogPart((entry) => ({
    ...entry,
    props: {
      title: 'Delete?',
      description:
        "Are you sure you want to delete this item? This action can't be undone.",
      icon: (
        <DialogIcon appearance="danger">
          <WarningTriangle />
        </DialogIcon>
      ),
      renderButtons({ close, confirm }) {
        return (
          <>
            <DialogClose onClick={close}>Cancel</DialogClose>
            <DialogAction onClick={confirm} appearance="danger">
              Delete
            </DialogAction>
          </>
        );
      },
      ...entry.props,
    },
    resolve: entry.resolve,
  })),
  warning: createDialogPart((entry) => ({
    ...entry,
    props: {
      title: 'Discard changes?',
      description: 'Are you sure you want to discard the changes?',
      icon: (
        <DialogIcon appearance="warning">
          <WarningTriangle />
        </DialogIcon>
      ),
      renderButtons({ close, confirm }) {
        return (
          <>
            <DialogClose onClick={close}>Cancel</DialogClose>
            <DialogAction onClick={confirm}>Discard</DialogAction>
          </>
        );
      },
      ...entry.props,
    },
    resolve: entry.resolve,
  })),
  confirmation: createDialogPart((entry) => ({
    ...entry,
    props: {
      title: 'Are you sure?',
      description: 'This action can not be undone.',
      icon: (
        <DialogIcon>
          <InformationSquare />
        </DialogIcon>
      ),
      renderButtons({ close, confirm }) {
        return (
          <>
            <DialogClose onClick={close}>Cancel</DialogClose>
            <DialogAction onClick={confirm}>Confirm</DialogAction>
          </>
        );
      },
      ...entry.props,
    },
    resolve: entry.resolve,
  })),
  success: createDialogPart((entry) => ({
    ...entry,
    props: {
      icon: undefined,
      title: 'Action successful',
      cover: <AlertDialogCover />,
      description: 'Woah! That action was successful!',
      headerProps: { align: 'center', ...entry.props.headerProps },
      footerProps: { align: 'stretch', ...entry.props.footerProps },
      renderButtons({ confirm }) {
        return <DialogAction onClick={confirm}>Continue</DialogAction>;
      },
      ...entry.props,
    },
    resolve: entry.resolve,
  })),
  build: createDialogPart((entry) => ({
    ...entry,
    props: {
      icon: undefined,
      cover: undefined,
      headerProps: { align: 'center', ...entry.props.headerProps },
      footerProps: { align: 'stretch', ...entry.props.footerProps },
      ...entry.props,
    },
    resolve: entry.resolve,
  })),
};

export function AlertDialogCover(props: Readonly<DialogCoverProps>) {
  const shouldRenderDialog = useMediaQuery('sm');

  if (shouldRenderDialog) {
    return <DialogCover {...props} />;
  }

  return <BottomSheetCover {...props} />;
}

export const AlertDialogThumbnail = createComponent<AlertDialogThumbnailProps>(
  (
    { className, src, illustration: Illustration = React.Fragment, ...props },
    ref,
  ) => (
    <figure
      aria-hidden="true"
      className="pt-8 md:pt-12 flex items-center justify-center"
    >
      {src ? (
        <Image
          src={src}
          ref={ref}
          width={300}
          height={169}
          className={cn('object-contain h-32 w-auto max-w-full', className)}
          {...props}
        />
      ) : (
        Illustration && <Illustration />
      )}
    </figure>
  ),
);
