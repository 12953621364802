import { Button } from '../button';
import { DialogCover } from '../dialog';
import { alertDialog } from './alert-dialog';

export function AlertDialogDemo() {
  return (
    <div className="flex gap-2 flex-wrap">
      <Button
        size="small"
        onClick={() => alertDialog.danger({ size: 'small' })}
      >
        Show danger
      </Button>
      <Button
        size="small"
        onClick={() =>
          alertDialog.warning({ headerProps: { align: 'center' } })
        }
      >
        Show warning
      </Button>
      <Button
        size="small"
        onClick={() =>
          alertDialog.confirmation({
            icon: undefined,
            cover: <DialogCover />,
            headerProps: { align: 'center' },
            footerProps: { align: 'stretch' },
          })
        }
      >
        Show confirmation
      </Button>
      <Button size="small" onClick={() => alertDialog.success()}>
        Show success
      </Button>
      <Button
        size="small"
        onClick={() => {
          alertDialog.loading();
          setTimeout(alertDialog.remove, 3000);
        }}
      >
        Show loading
      </Button>
    </div>
  );
}
