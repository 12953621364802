'use client';

import { useEffect } from 'react';

import { KamonaSpinner } from '@/ui/svgs/kamona-spinner';
import { MoniepointLogoLetter } from '@/ui/svgs/moniepoint-logo-letter';

import { cn } from '@/lib/utils';

export function PageLoader({
  background = 'transparent',
}: Readonly<{
  background?: 'opaque' | 'transparent';
}>) {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <div
      role="alert"
      aria-label="Loading"
      className={cn('fixed inset-0 !max-w-full z-loader', {
        'bg-neutral-700': background === 'opaque',
        'bg-black/80': background === 'transparent',
      })}
    >
      <div
        aria-hidden="true"
        className="absolute left-1/2 top-1/2 h-12 w-12 -translate-x-1/2 -translate-y-1/2 text-ui-border-default"
      >
        <KamonaSpinner className="absolute inset-0" />
        <MoniepointLogoLetter className="absolute left-1/2 top-1/2 h-auto w-6 -translate-x-1/2 -translate-y-1/2" />
      </div>
    </div>
  );
}
