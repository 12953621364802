import { defineVariants } from '@/ui/system';

export type CardVariants = typeof cardVariants;

export const cardVariants = defineVariants({
  base: 'flex gap-3 bg-background-card-default border-[1.5px] border-transparent outline-none',
  variants: {
    mode: {
      default: '',
      action: '',
    },
    loading: {
      true: 'bg-background-card-overlay animate-pulse',
      false: '',
    },
    selected: {
      true: '!border-ui-border-dark',
      false: '',
    },
    disabled: {
      true: 'opacity-50 pointer-events-none',
      false: '',
    },
    variant: {
      faded: 'bg-inset-default',
      default: 'bg-background-card-default',
      outline: 'border-ui-border-alternative',
    },
    radius: {
      small: 'rounded-radius3',
      medium: 'rounded-radius5',
      large: 'rounded-radius6',
    },
    layout: {
      vertical: 'flex-col p-3',
      horizontal: 'flex-row py-5 px-3',
      verticalWithHeader: 'flex-col gap-0',
    },
    align: {
      start: 'items-start',
      center: 'items-center',
      end: 'items-end',
      between: 'items-between',
      stretch: 'items-stretch',
    },
    justify: {
      start: 'justify-start',
      center: 'justify-center',
      end: 'justify-end',
      between: 'justify-between',
      stretch: 'justify-stretch',
    },
  },
  compoundVariants: [
    // Action
    {
      mode: 'action',
      selected: false,
      class: 'hover:bg-background-card-overlay',
    },
  ],
  defaultVariants: {
    align: 'center',
    justify: 'center',
    layout: 'horizontal',
    mode: 'default',
    radius: 'medium',
    selected: false,
    variant: 'default',
  },
});
