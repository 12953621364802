import { Logo } from '@kamona/components';
import { ReactNode } from 'react';

import { Link } from '@/lib/navigation';

interface LinkWrapperProps {
  children: ReactNode;
  disableNavigation?: boolean;
  className?: string;
}

interface HeaderLogoProps {
  className?: string;
  disableNavigation?: boolean;
}
const LinkWrapper = ({
  children,
  disableNavigation,
  className,
}: Readonly<LinkWrapperProps>) =>
  disableNavigation ? (
    children
  ) : (
    <Link href="/" aria-label="Moniepoint Home" className={className}>
      {children}
    </Link>
  );

export function HeaderLogo({
  className,
  disableNavigation,
}: Readonly<HeaderLogoProps>) {
  return (
    <LinkWrapper disableNavigation={disableNavigation} className={className}>
      <Logo size="small" className="h-8" />
    </LinkWrapper>
  );
}
