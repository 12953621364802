import { type ReactNode, useCallback } from 'react';

import {
  HTMLCustomVariantsAwareProps,
  createComponent,
  dataAttr,
  toNonInteractiveElement,
  useTailwindVariants,
} from '@/ui/system';

import { cn } from '@/lib/utils';

import { ChipVariants, chipVariants } from './chip.cx';

type BaseProps = HTMLCustomVariantsAwareProps<'button', ChipVariants>;

export interface ChipProps extends Omit<BaseProps, 'className'> {
  className?: string;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  mode?: 'action' | 'status';
}

export const Chip = createComponent<ChipProps>(
  (
    {
      as,
      children,
      startIcon,
      endIcon,
      className,
      mode = 'status',
      type = 'button',
      selected = false,
      disabled = false,
      ...props
    },
    ref,
  ) => {
    const isAction = mode === 'action';
    const Component = as || (isAction ? 'button' : 'span');

    const { classNames, ownProps } = useTailwindVariants(
      { ...props, mode, selected, disabled },
      chipVariants,
    );

    const renderIcon = useCallback(
      (icon: ReactNode) => {
        const usePlaceholder = !icon;

        return usePlaceholder
          ? toNonInteractiveElement(<span />)
          : toNonInteractiveElement(icon, { className: classNames.icon });
      },
      [classNames.icon],
    );

    return (
      <Component
        ref={ref}
        {...ownProps}
        type={type}
        disabled={disabled}
        aria-selected={selected}
        aria-disabled={disabled}
        data-has-end-icon={dataAttr(!!endIcon)}
        className={cn(className, classNames.root)}
        data-has-start-icon={dataAttr(!!startIcon)}
        role={props.role || (isAction ? 'button' : 'none')}
      >
        {renderIcon(startIcon)}
        {children}
        {renderIcon(endIcon)}
      </Component>
    );
  },
);

Chip.displayName = 'Chip';
